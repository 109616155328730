import React from 'react';
import { graphql } from 'gatsby';
import PostPage from 'components/pages/PostPage';

export default props => <PostPage {...props} />;

export const pageQuery = graphql`
  query($id: ID, $lang: GCMS_Lang, $langBlock: String) {
    postData: gcms {
      posts(where: { id: $id }) {
        createdAt
        updatedAt
        id
        metaDescription
        metaKeywords
        title
        slug
        content {
          html
          text
        }
        thumbnail {
          url
        }
      }
    }
    dataSimilarArticlesByDate: gcms {
      posts(
        where: { lang: $lang, NOT: { id: $id } }
        orderBy: createdAt_DESC
        first: 2
      ) {
        createdAt
        updatedAt
        title
        thumbnail {
          url
        }
        content {
          html
          text
        }
        slug
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "clientCase" }
          blockType: { eq: "linkBlock" }
          lang: { eq: $langBlock }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
    dataUseCaseLinks: allMarkdownRemark(
      filter: {
        frontmatter: { lang: { eq: $langBlock }, active: { eq: true } }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        frontmatter {
          useCases
        }
      }
    }
  }
`;
