import React from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import { Container } from 'components/basics';
import { Grid } from '@material-ui/core';
import Helmet from 'react-helmet';
import { breakpoint } from 'src/utils/mediaQueries';
import ShareBlock from 'components/pages/Post/Share/ShareBlock';
import PostContentBlock from 'components/pages/Post/PostContent/PostContentBlock';
import SimilarPostsBlock from 'components/pages/Post/SimilarPosts/SimilarPostsBlock';
import LinkBlocks from 'components/LinkBlock/LinkBlocks';
import LinkBlockBackground from 'components/pages/Solution/Marketing/LinkBlockBackground';

const LayoutBlock = styled(Layout)`
  text-align: left !important;
`;

const ContainerBlock = styled(Container)`
  ${breakpoint.down('sm')`
       padding: 0 1.5em;
    `}
`;
const LinkGrid = styled(Grid)`
  margin-top: -4rem;
  position: relative;
  padding-top: 10rem;
  padding-bottom: 4rem;
  background-position: top;
  ${breakpoint.down('lg')`
  padding-top: 6rem;padding-bottom: 2rem;margin-top: 0;`}
  ${breakpoint.down('md')`
  padding-bottom: 4rem;`}
  ${breakpoint.down('sm')`padding-top:0;padding-bottom: 6rem;`};
`;

const PostPage = props => {
  const data = props.data && props.data.postData.posts[0];
  const dataUseCaseLinks = props.data && props.data.dataUseCaseLinks.nodes;
  const similarArticles =
    props.data &&
    (!props.pageContext.keywords || props.pageContext.keywords.length === 0
      ? props.data.dataSimilarArticlesByDate &&
        props.data.dataSimilarArticlesByDate.posts
      : props.data.dataSimilarArticlesByKeywords &&
        props.data.dataSimilarArticlesByKeywords.posts);

  const linkBlocksData = {
    markdownContent: props.data && props.data.dataBlockLinkBody,
  };
  const seo = {
    title: data && `${data.title}`,
    keywords: (data && data.metaKeywords) || [],
    description: (data && data.metaDescription) || '',
  };

  return (
    <>
      <Helmet
        meta={[
          {
            property: 'og:url',
            content: props.location.href,
          },
          {
            property: 'og:type',
            content: 'post',
          },
          {
            property: 'og:title',
            content: data && data.title,
          },
          {
            property: 'og:description',
            content: data && data.metaDescription,
          },
          {
            property: 'og:image',
            content: data && data.thumbnail && data.thumbnail.url,
          },
        ]}
      />
      <LayoutBlock location={props.location} seo={seo}>
        <ContainerBlock>
          <Grid xs={12} sm={11} md={6} lg={7}>
            <PostContentBlock
              thumbnail={data && data.thumbnail && data.thumbnail.url}
              data={data}
              lang={props.pageContext.langBlock}
              dataUseCaseLinks={dataUseCaseLinks}
            />
            <ShareBlock data={data} location={props.location.href} />
            {similarArticles && similarArticles.length > 0 && (
              <SimilarPostsBlock
                data={similarArticles}
                lang={props.pageContext.langBlock}
              />
            )}
          </Grid>
        </ContainerBlock>
        <LinkGrid>
          <LinkBlocks
            data={
              linkBlocksData &&
              linkBlocksData.markdownContent &&
              linkBlocksData.markdownContent.nodes
            }
            lang={props.pageContext.langBlock}
          />
          <LinkBlockBackground />
        </LinkGrid>
      </LayoutBlock>
    </>
  );
};

export default PostPage;
