import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { breakpoint } from '../../../../utils/mediaQueries';
import { Link } from 'gatsby';
import { textBold, colors } from 'styles';
import Button from 'components/basics/Button';
import { FORM_TYPES } from 'components/pages/Index/FormModals/formConfigs';
import FormModal from 'components/pages/Index/FormModals/FormModal';
import Typography from '@material-ui/core/Typography';

const ThumbnailBlock = styled.div`
  background-image: url(${({ thumbnail }) => thumbnail});
  background-size: cover;
  min-width: 100%;
  min-height: 17em;
  border-radius: 20px;
  ${breakpoint.down('sm')`
    background-position: center;
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ReturnButton = styled(Button)`
  margin: 1.5em 0;
`;

const TitleBlock = styled(Typography)`
  ${textBold};
  text-transform: uppercase;
  ${breakpoint.down('sm')`
    font-size: 26px;
  `}
`;

const DateBlock = styled(Typography)`
  font-size: 1em;
  color: ${colors.grey};
  ${textBold};
  margin: 1em 0 1em 0;
`;

const ContentBlock = styled(Typography)`
  & span {
    display: flex;
    align-items: center;
  }

  h2,
  h3,
  h4 {
    width: 100%;
    text-align: left;
  }

  .css-1pmpp80 {
    margin: 1em !important;
    display: inline-block !important;
    height: 100%;
    width: 100%;
    ${breakpoint.down('sm')`
        width: 8em;
    `}
  }
`;

const UpdatedDate = styled.span`
  font-weight: normal;
  font-style: italic;
`;

const StyledButton = styled(Button)`
  margin-top: 4rem;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PostContentBlock = ({ data, lang, ...props }) => {
  const { dataUseCaseLinks } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const contentBlock = document && document.getElementById('content-block');
    contentBlock.innerHTML =
      contentBlock && data && data.content && data.content.html;
  }, [data]);

  return (
    <>
      <ThumbnailBlock
        thumbnail={data && data.thumbnail && data.thumbnail.url}
      />
      <StyledLink to={`/${lang}/blog`}>
        <ReturnButton
          customBackgroundColor="#DCDCDC"
          customTextColor="#00000066"
          customHoverBackgroundColor={colors.shades.grey.normal}
        >
          <FormattedMessage id="button.back" />
        </ReturnButton>
      </StyledLink>
      <TitleBlock variant="h5">{data && data.title}</TitleBlock>
      <DateBlock>
        <FormattedMessage id="blog.publishedOn" />
        &nbsp;
        <FormattedDate
          value={data && data.createdAt}
          year="numeric"
          month="long"
          day="2-digit"
        />
        <UpdatedDate>
          &nbsp; - <FormattedMessage id="blog.updatedOn" />
          &nbsp;
          <FormattedDate
            value={data && data.updatedAt}
            year="numeric"
            month="long"
            day="2-digit"
          />
        </UpdatedDate>
      </DateBlock>
      <ContentBlock
        id="content-block"
        dangerouslySetInnerHTML={{
          __html: data && data.content && data.content.html,
        }}
      />
      <StyledButtonContainer>
        <StyledButton
          size="large"
          variant="contained"
          color="primary"
          mt={2}
          onClick={() => handleOpen()}
          customBackgroundColor={colors.red}
          customHoverBackgroundColor={colors.shades.red.dark}
        >
          <FormattedMessage id="button.downloadACase" />
        </StyledButton>
      </StyledButtonContainer>
      <FormModal
        lang={lang}
        open={open}
        handleClose={handleClose}
        type={FORM_TYPES[`A_USE_CASE_${lang.toUpperCase()}`]}
        dataUseCaseLinks={dataUseCaseLinks}
      />
    </>
  );
};

export default PostContentBlock;
