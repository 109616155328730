import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { colors } from 'styles';
import Icon from 'components/basics/Icons';

const ContainerBlock = styled.div`
  margin: 4em 0;
  & a {
    margin-right: 2em;
  }

  & a:first-child {
    margin: 0 !important;
  }
`;

const IconBlock = styled.a`
  cursor: pointer;
  path {
    fill: ${colors.purple};
  }
`;

const openPopUp = url => {
  const left = screen.width / 2 - 600 / 2;
  const top = screen.height / 2 - 400 / 2;

  window.open(
    url,
    '',
    `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=${600},height=${400},top=${top},left=${left}`
  );
};

const ShareBlock = ({ data, location }) => (
  <ContainerBlock>
    <Typography variant="h6">
      <FormattedMessage id="blog.shareArticle" />
    </Typography>
    <br />
    <IconBlock
      onClick={() =>
        openPopUp(
          encodeURI(
            `https://www.linkedin.com/shareArticle?mini=true&url=${location}&title=${
              data && data.frontmatter.title
            }&source=${location}&summary=${data && data.excerpt}`
          )
        )
      }
      target="_blank"
    >
      <Icon icon="linkedin" widthIcon={32} heightIcon={32} />
    </IconBlock>
    <IconBlock
      onClick={() =>
        openPopUp(
          encodeURI(
            `https://twitter.com/intent/tweet/?text=${
              data && data.frontmatter.title
            }&url=${location}&via=qemotionapp"`
          )
        )
      }
      target="_blank"
    >
      <Icon icon="twitter" widthIcon={32} heightIcon={32} />
    </IconBlock>
    <IconBlock
      onClick={() =>
        openPopUp(
          encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${location}`)
        )
      }
    >
      <Icon icon="facebook" widthIcon={32} heightIcon={32} />
    </IconBlock>
  </ContainerBlock>
);

export default ShareBlock;
