import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import PostsListBlock from 'components/pages/Blog/PostsList/PostsListBlock';

const ContainerBlock = styled.div`
  margin: 5em 0;
  section {
    width: 100%;
  }
`;

const SimilarPostsBlock = ({ data, lang }) => (
  <ContainerBlock>
    <Typography variant="h6">
      <FormattedMessage id="blog.similarPosts" />
    </Typography>
    <PostsListBlock data={data} lang={lang} />
  </ContainerBlock>
);

export default SimilarPostsBlock;
